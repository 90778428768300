import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['chart'];

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.drawChart();
    }
  }

  drawChart() {
    let ctx = this.chartTarget.getContext('2d');
    let data = JSON.parse(this.data.get('data'))

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: [],
        datasets: [{
          label: '',
          backgroundColor: "#fbad78",
          data: [data.yes]
        }, {
          label: '',
          backgroundColor: "#6cd098",
          data: [data.no]
        }, {
          label: '',
          backgroundColor: "lightgrey",
          data: [data.not_sure]
        }]
      },
      options: {
        responsive: true,
        title: {
          display: this.data.get('title') ? true : false,
          position: 'bottom',
          text: this.data.get('title')
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            display: false
          }],
        }
      }
    })
  }
}
