import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['chart'];
  static values = {
    data: Array,
    labels: Array
  }

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.drawChart();
    }
  }

  drawChart() {
    let ctx = this.chartTarget.getContext('2d');

    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.labelsValue,
        datasets: this.dataValue
      },
      options: {
        title: {
          display: this.data.get('title') ? true : false,
          position: 'bottom',
          text: this.data.get('title')
        },
        legend: {
          display: true
        },
        tooltips: {
          enabled: true
        },
        scales: {
          xAxes: [{
            display: true,
            stacked: true,
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            display: false,
            stacked: true,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: false
            }
          }],
        }
      }
    })
  }
}
