import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["links", "template"]

  add_association(event) {
    event.preventDefault();

    var content = this.templateTarget.innerHTML;
    this.linksTarget.insertAdjacentHTML('beforebegin', content);
  }

  remove_association(event) {
    event.preventDefault();

    let wrapper = event.target.closest(".nested-fields");
    wrapper.remove()
  }
}
