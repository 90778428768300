import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["dateInput"];

  connect() {
    $(this.dateInputTarget).attr('placeholder', 'yyyy-mm-dd');
    $(this.dateInputTarget).datetimepicker({
      format: 'YYYY-MM-DD',
      // from paper dashboard: https://demos.creative-tim.com/paper-dashboard-2-pro/docs/1.0/plugins/datetimepicker.html
      icons: {
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right'
      }
    }).on('dp.change', () => {
      if (this.autosaveController) {
        this.autosaveController.save();
      }
    })
  }

  disconnect() {
    $(this.dateInputTarget).data("DateTimePicker").destroy();
  }

  get autosaveController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("autosave"), "autosave")
  }
}
