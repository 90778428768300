const weeklyReferralOptions = () => ({
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Referral Count',
        fontSize: 16,
        fontStyle: 'bold'
      },
      ticks: {
        padding: 15,
        fontSize: 14,
        fontStyle: 'bold'
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Week',
        fontSize: 16,
        fontStyle: 'bold'
      },
      ticks: {
        padding: 15,
        fontSize: 14,
        fontStyle: 'bold'
      }
    }]
  }
});

export default weeklyReferralOptions;
