import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener('turbolinks:load', function () {
      $('.selectpicker').selectpicker()
      $('.selectpicker').siblings('button').addClass('btn btn-sm')
    })
  }

  teardown() {
    $('.selectpicker').selectpicker('destroy').addClass('selectpicker')
  }
}
