import { Controller } from 'stimulus';

const BASE_RADIUS = 3
const SCALE_FACTOR = 0.6
export default class extends Controller {
  static targets = ['map'];
  static values = {
    data: Array,
    lowThreshold: Number,
    midThreshold: Number
  }

  connect() {
    this.icons = []
    this.colorIcons = {}
    this.lowThreshold = this.lowThresholdValue || 3;
    this.midThreshold = this.midThresholdValue || 10;
    if (typeof (google) != "undefined") {
      this.initializeMapAndLegends()
    }
  }

  initializeMapAndLegends() {
    this._drawMap();
    this._drawSiteBubbles();
    this._drawScheduledLegend();
    this._drawPatientCountLegend();
  }

  _drawMap() {
    if (this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: { lat: 34.499766, lng: -35.934795 },
        zoom: this.zoomValue || 1
      })
    }
    return this._map;
  }

  _drawSiteBubbles() {
    // Construct a bubble for each site
    // Color is based on % scheduled
    // Size is based on total number of patients matched to site
    this.dataValue.forEach((site) => {
      const contentString = `<h5 id="firstHeading" class="firstHeading">${site.site_name}</h5>` +
        `<div class="d-flex flex-wrap">` +
        `<div>Total: <strong>${site.total}</strong></div>` +
        `<span class="mx-1">/</span>` +
        `<div>Scheduled: <strong>${site.scheduled}</strong></div>` +
        `</div>`
      const infoWindow = new google.maps.InfoWindow({
        content: contentString
      });

      let siteCircle = new google.maps.Marker({
        map: this._map,
        icon: this._getCircle(site),
        position: { lat: Number(site.latitude), lng: Number(site.longitude) }
      });

      if (!this.icons[siteCircle.getIcon().legendScale]) {
        this.icons[siteCircle.getIcon().legendScale] = siteCircle.getIcon();
      }
      if (!this.colorIcons[siteCircle.getIcon().fillColor]) {
        this.colorIcons[siteCircle.getIcon().fillColor] = siteCircle.getIcon();
      }
      google.maps.event.addListener(siteCircle, 'click', function () {
        infoWindow.open(this._map, siteCircle);
      });
    })
  }

  _getCircle(site) {
    return {
      path: google.maps.SymbolPath.CIRCLE,
      fillOpacity: this._fillOpacity(site),
      fillColor: this._color(site),
      scale: this._scale(site.total),
      strokeColor: 'black',
      strokeWeight: 0.5,
      name: this._scaleName(site.total),
      count: site.total,
      legendScale: this._legendScale(site.total)
    }
  }

  _fillOpacity(site) {
    const low = (site.scheduled / site.total) * 100 < (this.lowThreshold + 1)
    const medium = (site.scheduled / site.total) * 100 < (this.midThreshold + 1)
    if (low) {
      return 0.6
    } else {
      return 0.8
    }
  }

  _scale(value) {
    // https://en.wikipedia.org/wiki/Proportional_symbol_map#Apparent_magnitude_(Flannery)_scaling
    return BASE_RADIUS * Math.pow(value, SCALE_FACTOR)
  }

  _scaleName(value) {
    if (value <= 5) {
      return '<= 5'
    } else if (value <= 10) {
      return '<= 10'
    } else if (value <= 25) {
      return '<= 25'
    } else {
      return '> 50'
    }
  }

  _color(site) {
    const low = (site.scheduled / site.total) * 100 < (this.lowThreshold + 1)
    const medium = (site.scheduled / site.total) * 100 < (this.midThreshold + 1)
    if (low) {
      return '#FF0000'
    } else if (medium) {
      return '#FFFF00'
    } else {
      return '#32CD32'
    }
  }

  _drawPatientCountLegend() {
    // add circle radius legend
    const radiusLegend = document.createElement('div')
    radiusLegend.setAttribute('id', 'radiusLegend')

    this._addTitle('Patient Count', radiusLegend)

    const ordered = Object.keys(this.icons).map(key => Number(key)).sort((a, b) => a - b)
    for (let i = 0; i < ordered.length; i++) {
      let type = this.icons[ordered[i]];
      let name = type.name;
      let scale = this._legendScale(type.count);
      let opacity = type.fillOpacity;
      let div = document.createElement('div');
      div.innerHTML = "<img src='data:image/svg+xml;utf8,<svg viewBox=\"0 0 100 100\" height=\"" + 1.5 * scale + "\" width=\"" + 1.5 * scale + "\" xmlns=\"http://www.w3.org/2000/svg\"><circle cx=\"50\" cy=\"50\" r=\"50\" style=\"fill: grey; stroke: white; stroke-width: 1;\" opacity=\"" + opacity + "\"/></svg>'> " + name;
      radiusLegend.appendChild(div);
    }

    this._map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(radiusLegend);
  }

  _legendScale(value) {
    if (value <= 5) {
      return this._scale(5)
    } else if (value <= 10) {
      return this._scale(10)
    } else if (value <= 25) {
      return this._scale(25)
    } else {
      return this._scale(50)
    }
  }

  _drawScheduledLegend() {
    const colorLegendNames = {
      '#FF0000': `0 - ${this.lowThreshold}% scheduled`,
      '#FFFF00': `${this.lowThreshold + 1} - ${this.midThreshold}% scheduled`,
      '#32CD32': `${this.midThreshold + 1}%+ scheduled`,
    }

    const colorLegend = document.createElement('div')
    colorLegend.setAttribute('id', 'colorLegend')

    this._addTitle('Scheduled', colorLegend)

    for (const key in this.colorIcons) {
      let type = this.colorIcons[key];
      let name = colorLegendNames[type.fillColor];
      let opacity = type.fillOpacity;
      let color = '%23' + type.fillColor.substring(1);
      let div = document.createElement('div');
      div.innerHTML = "<img src='data:image/svg+xml;utf8,<svg viewBox=\"0 0 100 100\" height=\"15\" width=\"15\" xmlns=\"http://www.w3.org/2000/svg\"><circle cx=\"50\" cy=\"50\" r=\"50\" style=\"fill:" + color + "; stroke: white; stroke-width: 1;\" opacity=\"" + 0.8 + "\"/></svg>'> " + name;
      colorLegend.appendChild(div);
    }

    this._map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(colorLegend);
  }

  _addTitle(title, element) {
    const titleElement = document.createElement("div");
    titleElement.setAttribute('class', 'title')
    titleElement.innerText = title
    element.appendChild(titleElement);
  }
}
