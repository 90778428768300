const utmSourcesOptions = () => ({
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    enabled: true,
      callbacks: {
      title: function (tooltipItem, data) {
        let idx = tooltipItem[0].index;
        let total = data.datasets[0].data[0];
        let count = data.datasets[0].data[idx];
        if (count === 0) return '0%';
        return `${Math.max(1, Math.ceil(count / total * 100))}%`;
      }
    }
  },
  legend: {
    display: false
  },
  scales: {
    yAxes: [{
      ticks: {
        fontStyle: 'bold'
      }
    }],
    xAxes: [{
      ticks: {
        beginAtZero: true,
        userCallback: function (label) {
          if (Math.floor(label) === label) {
            return label;
          }
        },
        fontStyle: 'bold'
      }
    }]
  }
});

export default utmSourcesOptions;
