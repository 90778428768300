import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import { notifySuccess, notifyInfo, notifyFailure, clearNotifications } from "src/notifications";

export default class AutosaveController extends Controller {
  static targets = ['form'];

  connect() {
    this.timeout = null;
    this.duration = this.data.get("duration") || 500;
    this.notificationPresent = false;
  }

  save() {
    clearTimeout(this.timeout);

    if (!this.notificationPresent) {
      clearNotifications();
      notifyInfo('Saving...');
      this.notificationPresent = true;
    }

    this.timeout = setTimeout(() => {
      this.notificationPresent = false;
      Rails.fire(this.formTarget, 'submit');
    }, this.duration);
  }

  success() {
    this.timeout = setTimeout(() => {
      clearNotifications();
      notifySuccess('Saved!');
    }, 500);
  }

  error() {
    this.timeout = setTimeout(() => {
      clearNotifications();
      notifyFailure('Unable to save. Please try again.');
    }, 500);
  }
}
