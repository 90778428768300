import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['chart'];

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.drawChart();
    }
  }

  drawChart() {
    let ctx = this.chartTarget.getContext('2d');
    let data = JSON.parse(this.data.get('data'))
    let formatter = this.data.get('formatter') || false;
    let max = data.max || 100

    new Chart(ctx, {
      plugins: [ChartDataLabels],
      type: 'bar',
      data: {
        labels: [data.first.label, data.second.label],
        datasets: [{
          label: [],
          backgroundColor: [data.first.color || "#fbad78", data.second.color || "#6cd098"],
          data: [data.first.data, data.second.data]
        }]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          datalabels: {
            color: 'white',
            formatter: function (value, context) {
              let func = eval(formatter)
              if (typeof func === 'function') {
                return func(value, data.max)
              } else {
                return ''
              }
            },
            textAlign: 'center',
            font: function (context) {
              let width = context.chart.width;
              let size = Math.round(width / 16);
              let min = 16
              let max = 20
              if (size < min) {
                size = min
              } else if (size > max) {
                size = max
              }
              return {
                size: size,
                weight: 'bold',
                family: '"Montserrat", "Helvetica Neue", Arial, sans-serif',
              };
            }
          }
        },
        title: {
          display: this.data.get('title') ? true : false,
          position: 'top',
          text: this.data.get('title')
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: true
        },
        scales: {
          xAxes: [{
            display: true,
            ticks: {
              fontStyle: 'bold',
              fontFamily: '"Montserrat", "Helvetica Neue", Arial, sans-serif'
            },
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: true,
              steps: 10,
              stepValue: data.max / 10,
              max: data.max
            },
            gridLines: {
              display: false
            }
          }],
        }
      }
    })
  }
}
