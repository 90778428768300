export function notifySuccess(message) {
  $.notify({ message: message }, { type: 'success' });
}

export function notifyInfo(message) {
  $.notify({ message: message }, { type: 'info' });
}

export function notifyFailure(message) {
  $.notify({ message: message }, { type: 'danger' });
}

export function clearNotifications() {
  $('div.alert').remove();
}
