const rankedAnalysisOptions = () => ({
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    enabled: true
  },
  legend: {
    display: true
  },
  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Count',
        fontSize: 16,
        fontStyle: 'bold'
      },
      ticks: {
        beginAtZero: true,
        padding: 15,
        fontSize: 14,
        fontStyle: 'bold'
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        fontSize: 16,
        fontStyle: 'bold'
      },
      ticks: {
        beginAtZero: true,
        fontStyle: 'bold'
      }
    }]
  }
});

export default rankedAnalysisOptions;
