import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['left', 'right']
  static values = {
    progress: Number
  }

  connect() {
    if (this.progressValue > 0) {
      if (this.progressValue <= 50) {
        this.rightTarget.style.transform = `rotate(${this._percentageToDegrees(this.progressValue)}deg)`
      } else {
        this.rightTarget.style.transform = 'rotate(180deg)'
        this.leftTarget.style.transform = `rotate(${this._percentageToDegrees(this.progressValue - 50)}deg)`
      }
    }
  }

  _percentageToDegrees(percentage) {
    return (percentage / 100) * 360
  }
}
