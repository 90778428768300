const spendOptions = {
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    mode: 'index',
    intersect: false,
    enabled: true,
    callbacks: {
      label: function (tooltipItem, data) {
        let idx = tooltipItem.datasetIndex;
        return data.datasets[idx].label + ': $' + tooltipItem.yLabel;
      }
    }
  },
  scales: {
    yAxes: [{
      stacked: true,
      ticks: {
        beginAtZero: true,
        fontStyle: 'bold',
        callback: function (value, index, values) {
          if ((value / 1000) % 1 === 0) {
            return '$ ' + (value / 1000) + 'k';
          }
        }
      },
      scaleLabel: {
        display: true,
        labelString: 'Spend (in thousands)',
        fontSize: 16,
        fontStyle: 'bold'
      }
    }],
    xAxes: [{
      stacked: true,
      ticks: {
        fontStyle: 'bold',
        callback: function (label) {
          if (/\s/.test(label)) {
            return label.match(/\b[\w']+(?:[^\w\n]+[\w']+){0,2}\b/g)
          } else {
            return label;
          }
        }
      }
    }]
  }
};

export default spendOptions;
