import { Controller } from 'stimulus';

export default class extends Controller {
  redrawMultiBarChart(event) {
    const element = event.target
    const source = element.dataset.source

    const data = JSON.parse(this.data.get('data'));
    const sourceData = JSON.parse(this.data.get('source-data'));

    const updatedData = {
      labels: data.labels,
      datasets: data.datasets.map((bar, index) => {
        return {
          ...bar,
          data: sourceData[source][this._indexToRank(index)]
        }
      })
    }

    this._redrawChart(updatedData);
    this._updateTitle(source);
  }

  redrawHorizontalBarChart(event) {
    const element = event.target;
    const source = element.dataset.source;

    const data = JSON.parse(this.data.get('data'));
    const sourceData = JSON.parse(this.data.get('source-data'));

    const updatedData = {
      labels: data.labels,
      datasets: data.datasets.map((bar, index) => {
        return {
          ...bar,
          data: sourceData[source].data
        }
      })
    }

    this._redrawChart(updatedData);
    this._updateTitle(source);
    this._updateCards(sourceData, source);
  }

  _indexToRank(index) {
    if (index === 0) return 'gold'
    if (index === 1) return 'silver'
    if (index === 2) return 'bronze'
  }

  _redrawChart(data) {
    const existingChart = this.chartController.chart;
    if (existingChart) existingChart.destroy();
    this.chartController.drawChart(data);
  }

  _updateTitle(source) {
    if (document.getElementById('utm-source-title')) {
      document.getElementById('utm-source-title').innerText = source;
    }
  }

  _updateCards(data, source) {
    console.log(data[source])
    if (document.getElementById('confirmed-enrolled')) {
      document.getElementById('confirmed-enrolled').innerText = data[source].confirmedEnrolled;
    }
    if (document.getElementById('forecasted-enrolled')) {
      document.getElementById('forecasted-enrolled').innerText = data[source].forecastedEnrolled;
    }
    if (document.getElementById('forecasted-retained')) {
      document.getElementById('forecasted-retained').innerText = data[source].forecastedRetained;
    }
    if (document.getElementById('acquisition-cost')) {
      document.getElementById('acquisition-cost').innerText = `$${data[source].acquisitionCost}`;
    }
  }

  get chartController() {
    const chartId = this.data.get('chart');
    return this.application.getControllerForElementAndIdentifier(document.getElementById(chartId), 'chart')
  }
}
