import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['chart'];

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.drawChart();
    }
  }

  drawChart() {
    let ctx = this.chartTarget.getContext('2d');
    let sentiment = this.data.get('data')

    new Chart(ctx, {
      type: "doughnut",
      data: {
        datasets: [{
          data: [sentiment, 10 - sentiment],
          backgroundColor: ["#6cd098", "lightgrey"],
        }, {
          data: [sentiment, 10 - sentiment],
          backgroundColor: ["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 0)"],
        }]
      },
      options: {
        cutoutPercentage: 0,
        rotation: -Math.PI,
        circumference: Math.PI,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        title: {
          display: true,
          text: sentiment,
          position: "bottom",
          fontSize: 30,
          padding: 0
        }
      }
    })
  }
}
