const weeklyRatioOptios = (chartData) => ({
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  tooltips: {
    enabled: true,
    callbacks: {
      title: function (tooltipItem, data) {
        let idx = tooltipItem[0].index;
        return chartData[idx].reason;
      },
      label: function (tooltipItem, data) {
        return `${tooltipItem.yLabel} referred / 1 enrolled`
      }
    }
  },
  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Referred to Enrollment Ratio',
        fontSize: 16,
        fontStyle: 'bold'
      },
      ticks: {
        beginAtZero: true,
        padding: 15,
        fontSize: 14,
        fontStyle: 'bold'
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Week',
        fontSize: 16,
        fontStyle: 'bold'
      },
      ticks: {
        padding: 15,
        fontSize: 14,
        fontStyle: 'bold'
      }
    }]
  }
});

export default weeklyRatioOptios;
