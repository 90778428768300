import { Controller } from 'stimulus';

import rankedAnalysisOptions from '../helpers/ranked_analysis_options';
import spendOptions from '../helpers/spend_options';
import utmSourcesOptions from '../helpers/utm_sources_options';
import weeklyRatioOptions from '../helpers/weekly_ratio_options';
import weeklyReferralOptions from '../helpers/weekly_referral_options';

export default class extends Controller {
  static targets = ['chart'];
  static values = {
    data: Object,
    chartType: String,
    options: String
  }

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.drawChart();
    }
  }

  drawChart(updatedData) {
    let ctx = this.chartTarget.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.chartTypeValue,
      data: updatedData || this.dataValue,
      options: this.getOptions()
    });
  }

  getOptions() {
    if (this.optionsValue === 'spend') {
      return spendOptions;
    } else if (this.optionsValue === 'ranked') {
      return rankedAnalysisOptions();
    } else if (this.optionsValue === 'utm-sources') {
      return utmSourcesOptions();
    } else if (this.optionsValue === 'weekly-referrals') {
      return weeklyReferralOptions();
    } else if (this.optionsValue === 'weekly-ratio') {
      const sourceData = JSON.parse(this.data.get('sourceData'));
      return weeklyRatioOptions(sourceData);
    } else {
      return {
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  }
}
