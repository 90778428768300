import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['userInput'];
  static values = {
    countries: Array
  }

  disconnect() {
    $(this.usersInputTarget).tagsinput('destroy');
  }

  connect() {
    // constructs the suggestion engine
    var countries = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      local: this.countriesValue
    });

    countries.initialize();

    let elt = $(this.userInputTarget)
    elt.tagsinput({
      tagClass: function(x) { return 'badge badge-default badge-pill my-1 mx-1 font-weight-bold' },
      allowDuplicates: false,
      typeaheadjs: [{
        hint: false,
        highlight: true,
        minLength: 1
      }, {
        freeInput: false,
        name: 'countries',
        source: countries,
          templates: {
            empty: '<div class="tt-suggestion tt-no-result">No results found.</div>'
          }
        }
      ],
      freeInput: false
    });

    this.countriesValue.forEach(country => {
      elt.tagsinput('add', country)
    })
  }
}
