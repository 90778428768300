import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {

  static targets = ["address", 'form'];
  static values = {
    latElement: String,
    lngElement: String,
  }

  connect() {
    if (typeof (google) != "undefined") {
      this.initializeAutocomplete();
    }
  }

  disconnect() {
    const element = this.addressTarget;
    if (element) {
      $(element).remove();
    }
  }

  initializeAutocomplete() {
    const element = this.addressTarget;

    if (element) {
      this. autocomplete = new google.maps.places.Autocomplete(element, { types: ['geocode'] });
      google.maps.event.addListener(this.autocomplete, 'place_changed', this._populateFullAddress.bind(this));
    }
  }

  _populateFullAddress() {
    let place = this.autocomplete.getPlace();
    console.log(this.latElementValue)
    let latitude_el = document.getElementById(this.latElementValue);
    let longitude_el = document.getElementById(this.lngElementValue);

    if (latitude_el && longitude_el) {
      console.log('getting set')
      latitude_el.value = place.geometry.location.lat();
      longitude_el.value = place.geometry.location.lng();
    }
  }
}
