import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const data = JSON.parse(this.data.get('data'))

    document.getElementById('submitSpendButton').addEventListener('click', () => {
      const categoryElement = document.getElementById('category');
      const selectedCategoryIdx = categoryElement.options[categoryElement.selectedIndex].value - 1;

      const studyElement = document.getElementById('study');
      const selectedStudyIdx = studyElement.options[studyElement.selectedIndex].value - 1;

      const spendElement = document.getElementById('spend');
      const spend = spendElement.value;

      data.datasets[selectedCategoryIdx].data[selectedStudyIdx] = Number(spend)

      this._redrawChart(data)
    })
  }

  _redrawChart(data) {
    const existingChart = this.chartController.chart;
    if (existingChart) existingChart.destroy();
    this.chartController.drawChart(data);
  }

  get chartController() {
    const chartId = this.data.get('chart');
    return this.application.getControllerForElementAndIdentifier(document.getElementById(chartId), 'chart')
  }
}
