import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['chart', 'max', 'value'];

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.drawChart();
    }
  }

  drawChart() {
    let ctx = this.chartTarget.getContext('2d');
    let value = this.data.get('value')
    let max = this.data.get('max')

    new Chart(ctx, {
      type: 'horizontalBar',
      data: {
        labels: [],
        datasets: [{
          data: [value],
          backgroundColor: "#fbad78"
        }, {
          data: [max - value],
          backgroundColor: "#6cd098"
        },]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          xAxes: [{
            display: false,
            stacked: true
          }],
          yAxes: [{
            display: false,
            stacked: true
          }],
        }
      }
    });
  }
}
